<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '950px' }"
    :header="'REGISTRAR NUEVA ORDEN DE COMPRA: ' + diaMesAnio(fecha_compra)"
    :modal="true"
    :maximizable="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <span><strong>SUCURSAL:</strong> </span>
          <Dropdown
            v-model="sucursalSelect"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione una Sucursal"
            :filter="true"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong
              >SELECCIONE PROVEEDOR: <span class="p-invalid">*</span></strong
            ></span
          >
          <Dropdown
            v-model="proveedorSelect"
            :options="proveedores"
            optionLabel="nombre_completo"
            :filter="true"
            placeholder="Seleccione un Proveedor"
            @change="buscarLaboratorios"
          >
          </Dropdown>
        </div>

        <div class="field col-12 md:col-4">
          <span><strong>LABORATORIO: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre"
            v-model="labSelected"
            :suggestions="laboratoriosListadoFiltrado"
            @complete="buscarLaboratorio($event)"
            placeholder="Buscar Laboratorio"
            @item-select="buscarLaboratorios"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.nombre }}
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-5">
          <span
            ><strong>PRODUCTOS DEL PROVEEDOR:</strong>
            <span
              style="
                font-size: 15px;
                color: #f0f0f0;
                background-color: #005f91;
                font-weight: bold;
                padding: 5px;
                border-radius: 5px;
              "
              v-if="cantidadProductosProveedor > 0"
              >{{ cantidadProductosProveedor }}</span
            >
          </span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="productoSeleccionado"
              :options="grupoProductos"
              placeholder="Seleccione un producto"
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              :filter="true"
              :disabled="!laboratoriosSelect.length"
              @change="agregarProductoDetalle"
            >
            </Dropdown>
            <Button
              style="
                font-size: 12px;
                font-weight: bold;
                color: #002236;
                border-color: #002236;
              "
              label="Ver Productos"
              icon="pi pi-eye"
              :disabled="cantidadProductosProveedor == 0"
              class="p-button-warning"
              v-tooltip.top="'Ver Listado Productos'"
              @click="activarListadoProductos"
            />
          </div>
          <small
            class="p-invalid text-red-500"
            v-if="errors.productoSeleccionado"
            >{{ errors.productoSeleccionado[0] }}</small
          >
        </div>
        <div class="field col-12 md:col-7">
          <span><strong>BUSCAR PRODUCTOS EN GRAL.:</strong></span>
          <Checkbox
            v-model="busqueda_caso_uso"
            :binary="true"
            class="mt-1 ml-2"
            v-tooltip.top="'Buscar por Principio Activo'"
          /><Badge class="bg-info text-white mt-1 ml-1">P.ACTIVO</Badge>
          <div class="p-inputgroup">
            <AutoComplete
              v-model="productoSeleccionadoTodos"
              :disabled="buscando_producto"
              field="descripcion"
              :delay="500"
              placeholder="Buscar Producto por código o nombre"
              :suggestions="productos_encontrados"
              @complete="buscarProductoPorCodigoDescripcion_todos"
              @item-select="agregarProductoTodosDetalle"
              @keyup.enter="buscarProductoPorCodigoDescripcion_todos"
              :dataKey="'id'"
              :filter="true"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }} **</strong>
                  {{ slotProps.item.fabrica_nombre }} **
                  <span
                    :class="
                      stockClass2(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock_actual)
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    }}</strong></span
                  ><strong v-show="slotProps.item.stock.length > 0">
                    ** PC:
                  </strong>
                  {{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].ultimo_precio_compra
                      : ""
                  }}<strong v-show="slotProps.item.stock.length > 0">
                    ** PV:
                  </strong>
                  {{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].ultimo_precio
                      : ""
                  }}
                  <span v-show="busqueda_caso_uso">
                    ** <strong>P.ACTIVO:</strong>
                    {{ slotProps.item.caso_uso ?? "" }}
                  </span>
                </div>
              </template>
            </AutoComplete>
            <Button
              icon="pi pi-plus"
              v-if="'Producto Crear' in auth.user.permissions"
              class="p-button-info"
              v-tooltip.top="'Nuevo Producto'"
              @click="activarProducto"
            />
          </div>
        </div>
        <div class="field col-12 md:col-12 text-center">
          <h3 style="border-bottom: 1px solid #005f91">
            <strong>DETALLE DE COMPRAS</strong>
          </h3>
          <DataTable
            ref="dtnuevasolicitudcompra"
            :value="detalleProductos"
            editMode="cell"
            @cell-edit-complete="onCellEditComplete"
            class="p-datatable-sm"
            responsive="true"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
          >
            <Column header="#" headerStyle="width:3rem">
              <template #body="slotProps">
                {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column field="producto.fabrica_nombre" header="LABORATORIO">
            </Column>
            <!-- <Column field="producto.id" header="CÓD. INTERNO">
              {{ data.producto.id }}
            </Column> -->
            <Column
              field="producto.descripcion"
              header="DESCRIPCIÓN/DETALLE"
              style="font-weight: bold"
            >
              {{ data.producto.descripcion }}
            </Column>
            <Column class="text-center" field="stock_actual" header="STOCK">
              <template #body="{ data }">
                <div :class="stockClass(data)">
                  {{
                    data.producto.stock.length > 0
                      ? sinDecimal(data.producto.stock[0].cantidad)
                      : 0
                  }}
                </div>
              </template>
            </Column>
            <Column
              field="cantidad"
              header="CANT/UNIDAD"
              class="text-right bg-green-200"
              style="font-weight: bold; width: 40px"
            >
              <template #body="{ data }">
                {{ data.cantidad }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  input-class="text-right"
                  v-model="data[field]"
                  locale="de-DE"
                  :useGrouping="false"
                />
              </template>
            </Column>
            <Column field="obs" header="OBS." class="text-center">
              <template #editor="{ data, field }">
                <Textarea v-model="data[field]" autoResize rows="1" />
              </template>
            </Column>
            <Column
              field="precio"
              header="P.UNIT."
              class="text-right"
              style="font-weight: bold"
            >
              <template #body="{ data }">
                {{ convertirNumeroGermanicFormat(data.precio) }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="text-right"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column
              field="subtotal"
              header="SUBTOTAL"
              class="text-right"
              style="font-weight: bold; width: 80px"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.cantidad * slotProps.data.precio
                  )
                }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="text-right"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column field="quantity" header="QUITAR" style="width: 3rem">
              <template #body="slotProps">
                <Button
                  @click="quitar(slotProps)"
                  v-tooltip.top="'Quitar Producto'"
                  icon="pi pi-trash"
                  class="p-button-danger p-button-rounded"
                />
              </template>
            </Column>
            <template #empty>
              <span
                style="
                  background-color: #f8d7da;
                  padding: 5px;
                  border-radius: 5px;
                  color: #721c24;
                "
                class="flex align-items-center justify-content-center p-invalid"
                >No existen Productos en el Detalle!</span
              >
            </template>

            <template #footer>
              <div class="text-right">
                <h4>
                  TOTAL OC:
                  <strong>{{
                    convertirNumeroGermanicFormat(totalCompra)
                  }}</strong>
                  Bs.
                </h4>
              </div>
            </template>
          </DataTable>
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>OBSERVACIONES: </strong> </span>
          <Textarea
            v-model="observacion"
            rows="1"
            autoResize
            placeholder="Ingrese una observación"
          >
          </Textarea>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-danger p-button-lg"
        @click="closeModal"
        :disabled="enviando"
        :loading="enviando"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        v-tooltip.top="'Guardar Compra'"
        @click="guardarCompra"
        :disabled="enviando"
        :loading="enviando"
      />
    </template>
  </Dialog>
  <ProveedorCreate
    :show="proveedorModal"
    :proveedor="proveedor"
    @closeModal="cerrarModalProveedor"
  >
  </ProveedorCreate>
  <ProductoCreate
    :mostrar="productoModal"
    :producto="producto"
    @closeModal="cerrarModalProducto"
    @actualizarListadoProductos="buscarLaboratorios"
  >
  </ProductoCreate>
  <ProductoListadoModal
    @closeModal="cerrarModalProductoResultados"
    :show="modalListadoProductos"
    :compra="true"
    :productos="productos"
    @agregarProductos="agregarProductos"
    :detalleProductos="detalleProductos"
  />
</template>

<script>
import ProveedorCreate from "@/module/proveedores/ProveedorCreate.vue";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";
import ProveedorService from "@/service/ProveedorService";
import OrdenCompraService from "@/service/OrdenCompraService";
import ProductService from "@/service/ProductService";
import ProductoListadoModal from "@/module/productos/ProductoListadoModal.vue";

import { useAuth } from "@/stores";

export default {
  name: "ComprasCreate",
  emits: ["closeModal", "actualizarListadoOC"],
  components: {
    ProveedorCreate,
    ProductoCreate,
    ProductoListadoModal,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    sucursales: {
      type: Array,
      default: () => [],
    },
    proveedores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      productoSeleccionadoTodos: null,
      productos_encontrados: [],
      buscar_producto: null,
      buscando_producto: false,
      productoSelect: [],
      productos: [],
      enviando: false,
      fecha_compra: new Date(),
      activarModal: this.mostrar,
      modalListadoProductos: false,
      proveedorSelect: {},
      laboratoriosSelect: {},
      laboratorios: [],
      grupoProductos: [],
      productoSeleccionado: {},
      precio: null,
      observacion: null,
      detalleProductos: [],
      errors: {},
      proveedorModal: false,
      productoModal: false,
      proveedor: { estado: { label: "Activo", value: 1 } },
      producto: { estado: { label: "Activo", value: 1 } },
      totalCompra: 0,
      cantidadProductosProveedor: 0,
      precio_compra_producto: null,
      stockClass: (data) => {
        if (data.stock_actual <= 0) {
          return "outofstock";
        } else if (data.stock_actual <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      stockClass2: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      busqueda_caso_uso: false,
      sucursalSelect: 1,
      labSelected: null,
      laboratoriosListadoFiltrado: [],
    };
  },
  proveedorService: null,
  ordenCompraService: null,
  productService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.proveedorService = new ProveedorService();
    this.ordenCompraService = new OrdenCompraService();
    this.productService = new ProductService();
  },
  methods: {
    buscarLaboratorio(event) {
      setTimeout(() => {
        this.ordenCompraService
          .buscarLaboratorioXNombre({ texto: event.query })
          .then((response) => {
            this.laboratoriosListadoFiltrado = [...response.laboratorios];
          });
      }, 250);
    },
    cerrarModalProductoResultados() {
      this.modalListadoProductos = false;
    },
    activarListadoProductos() {
      this.modalListadoProductos = true;
    },
    agregarProductos(productosSeleccionados) {
      productosSeleccionados.forEach((producto) => {
        try {
          //verifico si el producto ya existe en el detalle de productos para no agregarlo
          let existe = this.detalleProductos.find(
            (item) => item.producto_id == producto.id
          );
          if (!existe) {
            this.detalleProductos.push({
              ...producto,
              producto: { ...producto },
              cantidad: null,
              producto_id: producto.id,
              stock_actual:
                producto.stock.length > 0 ? producto.stock[0].cantidad : 0,
              /* sucursal_id: producto.stock[0].sucursal_id, */
              nombre_producto: producto.descripcion,
              cantidad_venta: null,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              precio: producto.precio_compra,
              ultimo_precio: parseFloat(producto.precio_sugerido),
              precio_sugerido: parseFloat(producto.precio_sugerido),
              total: parseFloat(producto.precio_sugerido * 1),
              obs: "",
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: "Advertencia",
              detail: "El producto ya se encuentra en la lista",
              life: 3000,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
      this.cerrarModalProductoResultados();
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    buscarProductoPorCodigoDescripcion() {
      setTimeout(() => {
        this.productService
          .buscar_x_codigo_nombre(this.productoSelect)
          .then((data) => {
            this.productos = data.productos;
            this.buscando_producto = false;
          })
          .catch((error) => {
            this.buscando_producto = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
      }, 200);
    },
    buscarProductoPorCodigoDescripcion_todos() {
      setTimeout(() => {
        let datos = {
          texto: this.productoSeleccionadoTodos,
          busqueda_caso_uso: this.busqueda_caso_uso,
          sucursal_id: this.sucursalSelect,
        };
        this.productService
          .buscar_x_codigo_nombre(datos)
          .then((data) => {
            this.productos_encontrados = data.productos;
            this.buscando_producto = false;
          })
          .catch((error) => {
            this.buscando_producto = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
      }, 250);
    },
    calcularTotal() {
      this.totalCompra = 0;
      this.detalleProductos.forEach((item) => {
        this.totalCompra += item.cantidad * item.precio;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarProductos() {
      this.productService.getAllProductos().then((data) => {
        this.productos = data.productos;
      });
    },
    cerrarModalProveedor() {
      this.proveedorModal = false;
      this.proveedor = {};
    },
    activarProducto() {
      this.productoModal = true;
      this.producto = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalProducto() {
      this.productoModal = false;
      this.producto = {};
    },
    validarCantidad(data) {
      if (data.cantidad <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "LA CANTIDAD DEBE SER MAYOR A CERO",
          life: 3000,
        });
        data.cantidad = 1;
      }

      /* this.productos.forEach((item) => {
        if (item.id == data.producto.value) {
          if (data.cantidad > item.stock_maximo && item.stock_maximo > 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "La cantidad supera el Stock Máximo de Compra" +
                "Permitido: " +
                item.stock_maximo,
              life: 3000,
            });
            data.cantidad = 1;
          }
        }
      }); */
    },
    onCellEditComplete(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      if (field == "precio") {
        data.precio = parseFloat(data.precio);
        this.calcularTotal();
      }

      if (field == "subtotal") {
        data.precio = data.subtotal / data.cantidad;
        this.calcularTotal();
        return;
      }

      this.validarCantidad(data);
      this.calcularTotal();
    },

    validarOrdenCompra() {
      if (this.proveedorSelect.id == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "SELECCIONE UN PROVEEDOR",
          life: 6000,
        });
        return false;
      }

      /* if (this.fecha_compra == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "SELECCIONE LA FECHA DE COMPRA",
          life: 6000,
        });
        return false;
      } */

      if (this.detalleProductos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "DEBE SELECCIONAR UN PRODUCTO COMO MINIMO",
          life: 6000,
        });
        return false;
      }

      let producto_cero = false;
      this.detalleProductos.forEach((item) => {
        if (item.cantidad <= 0) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "El producto " + item.producto.descripcion + " tiene cantidad 0",
            life: 6000,
          });
          producto_cero = true;
        }
      });

      if (producto_cero) {
        return false;
      }

      return true;
    },
    guardarCompra() {
      if (this.enviando) {
        return false;
      }
      if (this.validarOrdenCompra()) {
        this.enviando = true;

        let data = {
          proveedor_id: this.proveedorSelect.id,
          productos: this.detalleProductos,
          observacion: this.observacion,
          fecha_compra: this.fecha_compra,
          sucursal_id: this.sucursalSelect,
        };

        this.ordenCompraService
          .guardarOC(data)
          .then((response) => {
            if (!response.error) {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: "Nueva Orden de Compra Solicitada",
                life: 3000,
              });
              this.$emit("actualizarListadoOC");
              this.$emit("closeModal");
              this.closeModal();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.message,
                life: 6000,
              });
            }
            this.enviando = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "Error al guardar la Orden de Compra, Verifique su conexión a internet",
              life: 6000,
            });
            this.enviando = false;
          });
      }
    },
    quitar(producto) {
      this.detalleProductos.splice(producto.index, 1);
      this.calcularTotal();
    },
    buscarLaboratorios() {
      /*  if (this.proveedorSelect.id != this.proveedor_id) {
        this.productos = [];
      } */
      this.buscando_producto = true;
      this.productos = [];
      let datos = {
        sucursal_id: this.sucursalSelect,
        laboratorio_id: this.labSelected ? this.labSelected.id : null,
        proveedor_id: this.proveedorSelect.id,
      };
      this.proveedorService
        .obtenerLaboratorios(datos)
        .then((response) => {
          this.grupoProductos = [];
          this.cantidadProductosProveedor = 0;
          this.laboratorios = response.fabricas;
          this.laboratoriosSelect = response.fabricas;
          try {
            if (this.labSelected) {
              this.laboratorios.forEach((laboratorio) => {
                let items = [];
                this.productos.push(laboratorio);
                let item = {
                  label: laboratorio.descripcion,
                  value: laboratorio.id,
                };
                items.push(item);
                this.cantidadProductosProveedor++;
                let tmp = {
                  //cargo los productos del laboratorio seleccionado
                  label: laboratorio.descripcion,
                  code: laboratorio.id,
                  items: items,
                };
                this.grupoProductos.push(tmp);
                this.buscando_producto = false;
              });
            } else {
              this.laboratorios.forEach((laboratorio) => {
                let items = [];
                laboratorio.productos.forEach((producto) => {
                  this.productos.push(producto);
                  let item = {
                    label: producto.descripcion,
                    value: producto.id,
                  };
                  items.push(item);
                  this.cantidadProductosProveedor++;
                });

                let tmp = {
                  label: laboratorio.nombre,
                  code: laboratorio.id,
                  items: items,
                };
                this.grupoProductos.push(tmp);
                this.buscando_producto = false;
              });
            }
          } catch (error) {
            this.buscando_producto = false;
            console.log(error);
          }
        })
        .catch((error) => console.log(error));
    },

    closeModal() {
      this.activarModal = false;
      this.proveedorSelect = {};
      this.laboratoriosSelect = {};
      this.$emit("closeModal");
      this.grupoProductos = [];
      this.detalleProductos = [];
      this.observacion = null;
      this.cantidadProductosProveedor = 0;
      this.labSelected = null;
      this.productos = [];
    },

    agregarProductoTodosDetalle() {
      this.enviando = true;
      this.errors = {};
      //verifico si el producto ya esta agregado en el detalle de productos para no repetirlo
      let existe = false;
      this.detalleProductos.forEach((item) => {
        if (item.producto.id == this.productoSeleccionadoTodos.id) {
          existe = true;
        }
      });
      if (existe) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra agregado",
          life: 6000,
        });
        this.enviando = false;
        return false;
      }
      // obtengo el precio anterior del producto seleccionado
      let datos = {
        producto_id: this.productoSeleccionadoTodos.id,
      };
      this.ordenCompraService
        .getprecio_compra_producto(datos)
        .then((response) => {
          let tmp_producto = null;

          for (
            let index = 0;
            index < this.productos_encontrados.length;
            index++
          ) {
            if (
              this.productos_encontrados[index].id ==
              this.productoSeleccionadoTodos.id
            ) {
              tmp_producto = this.productos_encontrados[index];
            }
          }

          let item = {
            ...tmp_producto,
            producto: tmp_producto,
            stock_actual:
              tmp_producto.stock.length > 0
                ? tmp_producto.stock[0].cantidad
                : 0,
            cantidad: null,
            precio: parseFloat(response.stock.precio_compra || 0),
            subtotal: 0,
            obs: "",
          };
          this.detalleProductos.push(item);
          this.productoSelect = null;
          this.productoSeleccionadoTodos = null;
          this.calcularTotal();
        });
      this.enviando = false;
    },

    agregarProductoDetalle() {
      this.enviando = true;
      this.errors = {};
      //verifico si el producto ya esta agregado en el detalle de productos para no repetirlo
      let existe = false;
      this.detalleProductos.forEach((item) => {
        if (item.producto.id == this.productoSeleccionado.value) {
          existe = true;
        }
      });
      if (existe) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra agregado",
          life: 6000,
        });
        this.enviando = false;
        return false;
      }
      // obtengo el precio anterior del producto seleccionado
      let datos = {
        producto_id: this.productoSeleccionado.value,
      };
      this.ordenCompraService
        .getprecio_compra_producto(datos)
        .then((response) => {
          let tmp_producto = null;

          for (let index = 0; index < this.productos.length; index++) {
            if (this.productos[index].id == this.productoSeleccionado.value) {
              tmp_producto = this.productos[index];
            }
          }

          let item = {
            ...tmp_producto,
            producto: tmp_producto,
            cantidad: null,
            precio: parseFloat(response.stock.precio_compra || 0),
            subtotal: 0,
            obs: "",
          };
          this.detalleProductos.push(item);
          this.productoSelect = {};
          this.productoSeleccionado = {};
          this.calcularTotal();
        });
      this.enviando = false;
    },
    diaMesAnio(fecha) {
      let date = new Date(fecha);
      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("es-ES", options);
    },
  },
  watch: {
    mostrar(newVal) {
      this.activarModal = newVal;
      this.calcularTotal();
    },
    /*  productoSeleccionado() {
      if (this.productoSeleccionado) {
        this.agregarProductoDetalle();
      }
    }, */
  },
};
</script>

<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
